.chat-bubble-sent {
  text-align: right;
  background: #e6f7ff;
  padding: 10px;
  border-radius: 8px;
  margin: 5px 0;
  color: black;
  animation: slideInRight 0.5s ease;
}

.chat-bubble-received {
  text-align: left;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  margin: 5px 0;
  color: black;
  animation: slideInLeft 0.5s ease;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.loading-spinner {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
