.layout-style {
  height: 100vh;
  width: 100vw;
  position: fixed;
  }

.clear-filters{
display: flex !important;
justify-content:center !important;
background-color: rgb(235, 73, 73) !important;
font-weight:600 !important;
color: white !important;
margin: 0 !important;
}


.clear-filters.ant-menu-item-selected {
background-color: rgb(235, 73, 73) !important;
}


.ant-layout-content{
position: relative;
}

.ant-layout-sider-children {
height: 100% !important;
overflow: auto !important;
}

.ant-menu-inline-collapsed .ant-menu-submenu-title{
  display: block !important;
  white-space: nowrap !important;
}

.ant-menu-inline-collapsed{
width: 50px !important;
}
.ant-menu-submenu-title{
height: auto !important;
white-space: normal !important;
line-height: 40px !important;
}

.fb i{
display: none;
}

.twt i{
display: none;
}

.fb, .twt{
display: flex !important;
justify-content:flex-start !important;
margin: 0 !important;
}
.fb{
background-color: #3b5998 !important ;
color: white !important;
}

.twt{
background-color: #1DA1F2 !important;
color: white !important;
}

.ant-drawer-body{
padding-top: 0 !important;
}

/*Wrap Text and Flex*/
.ant-menu-item{
padding-left: 25px !important;
height: auto !important;
white-space: normal !important;
display: flex !important;
justify-content: space-between;
}

/*
.ant-select-selector{
height: 120px  !important;
}*/

.ant-layout-sider, .ant-layout-sider > .ant-layout-sider-trigger{
flex: 0 0 200px !important;
max-width: 250px !important;
min-width: 250px !important;
width: 250px !important;
}

.ant-layout-sider-collapsed, .ant-layout-sider-collapsed > .ant-layout-sider-trigger {
flex: 0 0 50px !important; 
max-width: 50px !important; 
min-width: 50px !important; 
width: 50px !important;
}


.ant-menu-item-selected {
background-color:  rgba(0,0,0,0.5) !important;
}

.ant-switch.ant-switch-checked {
background-color: #1890ff !important;
}

/*FB x TWT icons*/
.anticon-facebook, .anticon-twitter{
padding-top:13px !important;
}

/*Toggle*/
.ant-switch{
margin-top: 10px !important;
margin-left: auto !important;
background-color: rgba(225, 233, 227, 0.5) !important;
}

/*SIDER*/
.site-layout-content {
min-height: 280px;
padding: 24px;
background: #fff;
}
#components-layout-demo-top .logo {
float: left;
width: 120px;
height: 31px;
margin: 16px 24px 16px 0;
background: rgba(255, 255, 255, 0.3);
} 
.ant-row-rtl #components-layout-demo-top .logo {
float: right;
margin: 16px 0 16px 24px;
}

.gm-style .gm-style-iw-c {
  background-color: #002140;
  color: white !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece { 
  background-color: #002140;
}

.gm-style .gm-style-iw-tc::after {
  background-color: #002140;
}

.gm-ui-hover-effect>span {
  background-color: #fff;
}

